<template>
  <div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-md-6 col-sm-12">
            <h4 class="font-weight-light">
              {{ $t("general.add") }}
              <span class="font-weight-bold">{{ $t("shipment.label") }}</span>
            </h4>
          </div>
          <div class="col-md-6 col-sm-12"></div>
        </div>
      </div>
    </div>

    <section class="content">
      <div class="container-fluid">
        <div v-if="loading">
          <Spinner size="md"
                   variation="primary" />
        </div>
        <div v-else>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="checkDuplicate"
                  novalidate>
              <div class="row">
                <!-- Order Details -->
                <div class="col-md-6 col-sm-12">
                  <Card id="order-details-card">
                    <div slot="body">
                      <h5 class="header-text text-color-4 mb-2">
                        {{ $t("shipment.edit.order_details") }}
                      </h5>
                      <div class="row">
                        <div class="col">
                          <TextBox type="text"
                                   id="order"
                                   :label="$t('shipment.edit.fields.order_no')"
                                   v-model="orderCreateForm.orderCode"
                                   rules="required" />
                        </div>
                        <!-- <div class="col-6">
                          <TextBox
                            type="text"
                            id="agent"
                            :label="$t('general.agent')"
                            :value="orderCreateForm.user"
                            rules="required"
                          />
                        </div> -->
                      </div>

                      <div class="row mb-4">
                        <div class="col-12 col-md-6">
                          <Select v-if="salesPersonOptions && salesPersonOptions.length
                          "
                                  id="sales-person"
                                  :label="$t('shipment.edit.fields.sales_person')"
                                  :options="salesPersonOptions"
                                  v-model="orderCreateForm.salesPerson" />
                        </div>

                        <div class="col-12 col-md-6">
                          <Select id="shops"
                                  :label="$t('shipment.edit.fields.shop')"
                                  :options="shopOptions"
                                  v-model="orderCreateForm.shopId"
                                  :value="orderCreateForm.shopId"
                                  @input="defaultWeight" />

                          <!-- <label v-if="selectedShopError" class="text-danger text-xs">{{ selectedShopError.message }}</label> -->
                        </div>
                      </div>

                      <div class="row mb-2">
                        <div class="col-12">
                          <div class="d-flex justify-content-between">
                            <label>
                              {{ $t("shipment.edit.fields.shipping_methods") }}
                            </label>
                          </div>

                          <ValidationProvider rules="required">
                            <ShippingMethodInput :options="shippingMethods"
                                                 :values="orderCreateForm.shippingMethod"
                                                 v-model="orderCreateForm.shippingMethod"
                                                 variant="info"
                                                 size="sh"
                                                 justify="left"
                                                 :multiple="false"
                                                 wrapper_class="mt-2 mb-2"
                                                 @input="handleShippingChange" />
                          </ValidationProvider>

                          <div class="alert alert-warning"
                               v-if="orderCreateForm.shippingMethod && orderCreateForm.shippingMethod.includes('jt')">
                            ขนส่ง J&T express มีค่าบริการ 0.5 บาท ต่อ 1 รายการ
                          </div>

                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <NumberField id="totalSalesPrice"
                                       v-model.number="orderCreateForm.totalSalesPrice"
                                       :label="$t('shipment.edit.fields.total_sales_price')
                                        "
                                       wrapper_class="pb-0"
                                       @input="handleDiscountChange()" />
                        </div>

                        <div class="col">
                          <NumberField id="shippingCostCharged"
                                       v-model.number="orderCreateForm.shippingCostCharged"
                                       :label="$t('shipment.edit.fields.shipping_cost_charged')
                                        "
                                       wrapper_class="pb-0"
                                       fg_wrapper_class="mb-0"
                                       @input="handleDiscountChange()" />
                          <label class="--text-vvsm text-danger">ระบบจะบวกเข้ายอด COD อัตโนมัติ</label>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <NumberField id="weight"
                                       v-model.number="orderCreateForm.weight"
                                       :label="$t('general.weight')"
                                       wrapper_class="pb-4"
                                       :rules="weightValidationRules" />
                        </div>

                        <div class="col-6"
                             v-if="orderCreateForm.shippingMethod &&
                              orderCreateForm.shippingMethod.length &&
                              (orderCreateForm.shippingMethod[0].toLowerCase() ==
                                'thaipost' ||
                                orderCreateForm.shippingMethod[0].toLowerCase() ==
                                'thaipost0') &&
                              boxSizeOptions &&
                              boxSizeOptions.length
                            ">
                          <Select id="box-sizes"
                                  :label="$t('shipment.edit.fields.box_size')"
                                  :options="boxSizeOptions"
                                  v-model="orderCreateForm.boxSize"
                                  rules="required" />
                        </div>
                      </div>

                      <!-- Discount -->
                      <div class="row">
                        <div class="col-6">
                          <label>{{
                            $t("shipment.edit.fields.discount_field")
                          }}</label>

                          <div class="mb-3">
                            <Toggle id="has-discount"
                                    :value="discountToggle"
                                    variant="primary"
                                    v-model="discountToggle"
                                    :labels="{
                                      true: $t(
                                        'shipment.edit.fields.order_has_discount'
                                      ),
                                      false: $t(
                                        'shipment.edit.fields.orders_has_no_discount'
                                      ),
                                    }"
                                    @input="handleDiscountChange()" />
                          </div>
                        </div>
                        <div class="col-6"
                             v-if="discountToggle">
                          <NumberField id="discountAmount"
                                       v-model.number="orderCreateForm.discountAmount"
                                       @input="handleDiscountChange()"
                                       :label="$t('shipment.edit.fields.discount_amount')"
                                       wrapper_class="pb-0"
                                       fg_wrapper_class="mb-0" />
                          <label class="--text-vvsm text-danger">ระบบจะลดจากยอด COD อัตโนมัติ</label>
                        </div>
                      </div>

                      <!-- Bank Select -->
                      <Toggle id="has-bank-info"
                              :value="bankToggle"
                              variant="primary"
                              v-model="bankToggle"
                              :labels="{
                                true: $t('bank_input.save_bank_info'),
                                false: $t('bank_input.do_not_save_bank_info'),
                              }"
                              @input="handleBankChange()" />

                      <div class="row mt-3"
                           v-if="bankToggle">
                        <div class="col-12">
                          <label>{{ $t("bank_input.select_a_bank") }}</label>
                          <BankSelectInput v-model="orderCreateForm.bank.name" />

                          <NumberField id="amount"
                                       :label="$t('bank_input.amount')"
                                       wrapper_class="pb-0"
                                       fg_wrapper_class="mb-0 mt-4"
                                       v-model.number="orderCreateForm.bank.amount" />

                          <div class="row mt-4">
                            <div class="col-6">
                              <DatePicker id="date-picker"
                                          :label="$t('bank_input.date')"
                                          custom_class="align-self-center mr-2 mb-0"
                                          v-model="orderCreateForm.bank.date" />
                            </div>

                            <div class="col-6">
                              <TextBox type="text"
                                       id="time-picker"
                                       :label="$t('bank_input.time')"
                                       v-model="orderCreateForm.bank.time" />
                            </div>
                          </div>

                          <div class="mt-4"></div>

                          <DropZone ref="dropzone"
                                    v-model="orderCreateForm.bank.slip"
                                    :label="$t('bank_input.upload_bank_slip')"
                                    size="sm"
                                    clearOption />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>

                <!-- Customer Details -->
                <div class="col-md-6 col-sm-12">
                  <Card id="customer-details-card">
                    <div slot="body">
                      <h5 class="header-text text-color-4 mb-2">
                        {{ $t("shipment.edit.customer_details") }}
                      </h5>
                      <div class="row">
                        <!-- <div class="col-md-6 col-sm-12">
                          <TextBoxAddon
                            type="number"
                            id="customer-mobile"
                            :label="$t('general.mobile')"
                            v-model="orderCreateForm.customer.mobileNo"
                            :maxval="10"
                            rules="phone"
                            :addon_btn_text="$t('shipment.edit.fields.check')"
                            @addonClicked="checkPhoneNo"
                            :loading="phoneCheckLoading"
                          />
                        </div> -->

                        <div class="col-12">
                          <PhoneNumber id="customer-phone"
                                       :label="$t('general.phone')"
                                       v-model="orderCreateForm.customer.phoneNo"
                                       rules="phone|required"
                                       @change="handlePhoneNoChange" />
                          <p class="text-info --text-vsm mt-n4 mb-4"
                             v-if="phoneNoInsights.returned ||
                              phoneNoInsights.sameShop
                            ">
                            {{ phoneNoInsights.returned }}
                            <br />
                            {{ phoneNoInsights.sameShop }}
                          </p>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <TextBox type="text"
                                   id="customer-name"
                                   :label="$t('general.name')"
                                   v-model="orderCreateForm.customer.name" />
                        </div>
                        <div class="col-md-6">
                          <TextBox type="text"
                                   id="customer-email"
                                   :label="$t('general.email')"
                                   v-model="orderCreateForm.customer.email" />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <ValidationProvider rules="required|address">
                            <SmartAddress id="customer-address"
                                          :label="$t('general.address.address')"
                                          :placeholder="`ตัดลอกข้อมูลผู้รับพัสดุจากช่องทางต่างๆ แล้วจัดวาง ชื่อ เบอร์โทรศัพท์ และที่อยู่ในช่องนี้\n\nคุณตัวอย่าง การจัดส่ง\n0899991234\n1/1 ถนนร่ารวย\nสีลม บางรัก\nกรุงเทพ 10500`"
                                          :value="orderCreateForm.customer.address"
                                          v-model="orderCreateForm.customer.address"
                                          rows="7"
                                          rules="required"
                                          @input="autofillCustDetails"
                                          :isThaiPost="isThaiPost"
                                          :sm="orderCreateForm.shippingMethod[0]" />
                          </ValidationProvider>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6">
                          <ValidationProvider>
                            <Select id="sales-channel"
                                    :label="$t('form.shipment.sales_channel')"
                                    :options="[
                                      { name: 'Facebook', value: 'facebook' },
                                      { name: 'Line', value: 'line' },
                                      { name: 'Instagram', value: 'instagram' },
                                      { name: 'Web', value: 'web' },
                                      { name: 'TikTok', value: 'tiktok' },
                                      { name: 'Lazada', value: 'lazada' },
                                      { name: 'Shopee', value: 'shopee' },
                                      { name: 'Other', value: 'other' },
                                    ]"
                                    v-model="orderCreateForm.customer.salesChannel" />
                          </ValidationProvider>
                        </div>
                        <div class="col-6">
                          <TextBox type="text"
                                   id="customer-social"
                                   :label="$t('form.shipment.customer_fb_line')"
                                   v-model="orderCreateForm.customer.socialLink"
                                   input_wrapper_class="pb-0" />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-6">
                          <label>{{
                            $t("shipment.edit.fields.payment_mode")
                          }}</label>

                          <div class="mb-3">
                            <Toggle id="is-cod"
                                    :value="codToggle"
                                    variant="primary"
                                    v-model="codToggle"
                                    :labels="{
                                      true: $t(
                                        'shipment.edit.fields.payment_method_cod'
                                      ),
                                      false: $t(
                                        'shipment.edit.fields.payment_method_online'
                                      ),
                                    }"
                                    @input="handleDiscountChange()" />
                          </div>
                        </div>
                        <div class="col-6">
                          <NumberField v-if="codToggle"
                                       id="codAmount"
                                       v-model.number="orderCreateForm.codAmount"
                                       :label="$t('shipment.edit.fields.cod_amount')"
                                       wrapper_class="pb-0"
                                       fg_wrapper_class="mb-0" />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <TextArea id="remark"
                                    :label="$t('shipment.edit.fields.remarks')"
                                    v-model="orderCreateForm.remarks"
                                    rows="3"
                                    :maxlength="500" />

                          <Toggle v-if="orderCreateForm.shippingMethod && orderCreateForm.shippingMethod == 'flash'"
                                  id="has-happy-return"
                                  :value="orderCreateForm.happyReturn"
                                  variant="primary"
                                  v-model="orderCreateForm.happyReturn"
                                  :labels="{
                                    true: 'Happy Return',
                                    false: 'Happy Return',
                                  }" />
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>

              <OrdersProductTable v-if="showProducts"
                                  @change="handleProductsChange" />

              <div class="row mt-2 mb-4">
                <div class="col">
                  <div class="alert alert-info mb-4" style="width: 400px; margin: 0 auto;"
                       v-if="orderCreateForm.shippingMethod && orderCreateForm.shippingMethod.includes('jt')">
                    ขนส่ง J&T express มีค่าบริการ 0.5 บาท ต่อ 1 รายการ
                  </div>

                  <div class="d-flex mt-4 justify-content-center">
                    <Button type="submit"
                            value="save"
                            class="btn btn-success mr-4"
                            :disabled="invalid || !isValidAddress"
                            size="lg"
                            :loading="createLoading">ส่งเข้าระบบ</Button>
                    <Button type="button"
                            class="btn btn-danger mr-4"
                            @click="cancel()"
                            size="lg">ยกเลิก</Button>
                    <Button type="submit"
                            value="draft"
                            class="btn btn-secondary"
                            :disabled="invalid || !isValidAddress || !['kerry', 'thaipost'].includes(orderCreateForm.shippingMethod[0])"
                            :loading="createLoading"
                            size="lg">บันทึกร่าง</Button>
                  </div>
                </div>
              </div>

              <p class="text-center text-danger mt-0"
                 v-if="hasSurcharge">
                <i class="fal fa-exclamation-triangle"></i>

              <pre class="text-danger"
                   v-if="orderCreateForm.shippingMethod[0] === 'thaipost'">
ถึง...ลูกค้าคนสำคัญของไปรษณีย์ไทย
เนื่องจากต้นทุนน้ำมันการขนส่ง
ทางบก ทางเรือ และค่าแรงงานในพื้นที่เศรษฐกิจ
และแหล่งท่องเที่ยวสูงกว่าที่อื่นๆ
จึงจำเป็นต้องคิดค่าบริการเพิ่มขึ้น 15 บาทต่อชิ้น
หากมีการเปลี่ยนแปลงทาง ปณท จะประกาศให้ทราบอีกครั้ง
โดยไปรษณีย์ไทยยังคงรักษามาตรฐานการนำจ่าย
และดูแลสิ่งของทุกชิ้นจนถึงมือผู้รับอย่างดีที่สุด
                </pre>

              <span v-else>
                รายการสั่งซื้อของท่านอยู่ในพื้นที่ห่างไกลของบริษัทขนส่งที่ท่านเลือก
              </span>
              </p>

              <!-- Check duplicate Modal -->
              <Modal id="duplicate-error-modal"
                     custom_dialog_class="modal-dialog-centered"
                     :backdrop="true">
                <div slot="body"
                     v-if="dupOrder">
                  <h3 class="dup-header-text text-color-4 mb-4 mt-4">
                    <span>❗❗</span> คุณลงออเดอร์ซ้ำ<span>❗❗</span>
                  </h3>

                  <div class="alert alert-danger">
                    <p class="lead text-center mt-2">
                      <i class="fas fa-exclamation-triangle mr-2 --text-lg mb-4"></i>
                      <br>
                      <span>
                        เราเจอรายการคำสั่งซื้อเบอร์โทรเดียวกัน {{ dupOrder.phoneNo }} ของ {{ dupOrder.customerName }}
                        วันนี้
                      </span>
                    </p>
                  </div>

                  <div class="mt-4">

                    <h6 class="mb-2">
                      รายละเอียด ออเดอร์ก่อน
                    </h6>

                    <table class="text-muted --text-sm">
                      <tr>
                        <td>สร้างเมื่อ: </td>
                        <td> {{ dupOrder.createdAt | formatDateTime }} </td>
                      </tr>

                      <tr>
                        <td>ลูกค้า: </td>
                        <td> {{ dupOrder.customerName }} </td>
                      </tr>

                      <tr>
                        <td>เบอร์โทรศัพท์: </td>
                        <td>{{ dupOrder.phoneNo }}</td>
                      </tr>

                      <tr>
                        <td>หมายเลขการติดตาม: </td>
                        <td>{{ dupOrder.trackingNo }}</td>
                      </tr>

                      <tr>
                        <td>COD: </td>
                        <td>{{ dupOrder.codAmount }}</td>
                      </tr>
                    </table>

                  </div>

                  <div class="d-flex justify-content-end mt-4">

                    <!-- <span v-if="duplicateTimeLimit" class="align-self-center mr-2 text-muted">
                      รอ {{ duplicateTimeLimit }} วินาที
                    </span> -->

                    <Button id="btn-duplicate-error-no"
                            type="button"
                            variant="danger"
                            custom_class="mr-2 text-white"
                            @click="cancelDuplicateErrorModal()"
                            size="md">แก้ไขข้อมูล</Button>

                    <button id="btn-duplicate-error-yes"
                            type="button"
                            value="save"
                            class="btn btn-default btn-md"
                            :class="{ 'text-white': duplicateTimeLimit <= 0 }"
                            @click="handleFormSubmit()"
                            :disabled="duplicateTimeLimit > 0"
                            :style="{ backgroundPosition: `${dupTimerPercentage.x}% ${dupTimerPercentage.y}%` }">
                      ลงออเดอร์ซ้ำ
                    </button>
                    <!-- 
                    <Button id="btn-duplicate-error-yes" :disabled="duplicateTimeLimit > 0" type="button"
                      variant="default" @click="handleFormSubmit()" size="md"> ดำเนินการต่อ </Button> -->
                  </div>
                </div>
              </Modal>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </section>

    <audio src="@/assets/error.mp3"
           hidden
           id="error-audio"></audio>
  </div>
</template>

<script>
import Vue from "vue";
import Card from "@/components/core/Card";
import Button from "@/components/core/Button";
import TextBox from "@/components/core/TextBox";
import TextBoxAddon from "@/components/core/TextBoxAddon";
import PhoneNumber from "@/components/derived/PhoneNumber";
import Spinner from "@/components/core/Spinner";
import TextArea from "@/components/core/TextArea";
import Toggle from "@/components/core/Toggle";
import NumberField from "@/components/core/NumberField";
import Select from "@/components/core/Select";
import CheckBoxGroup from "@/components/core/CheckBoxGroup";
import RowControls from "@/components/core/RowControls";
import CardWidget from "@/components/core/CardWidget.vue";
import Modal from "@/components/core/Modal";
import RadioGroup from "@/components/core/RadioGroup";
import ShippingMethodInput from "@/components/derived/ShippingMethodInput";
import OrdersProductTable from "@/components/derived/OrdersProductTable";
import SmartAddress from "@/components/derived/SmartAddress";
import BankSelectInput from "@/components/derived/BankSelectInput";
import DatePicker from "@/components/core/DatePicker";
import TimePicker from "@/components/core/TimePicker";
import DropZone from "@/components/derived/DropZone";
import { shippingInfo } from "@/helpers/core";
import { FILTERS } from "@/helpers/filters";
import { ToastFix } from "@/helpers/toastr";
import { mapGetters } from "vuex";
import { i18n } from "@/i18n";
import axios from "axios";
import { checkSurcharge } from "@/helpers/shippingMethod";

export default {
  name: "Seller-Order-QuickCreate",
  components: {
    Card,
    TextBox,
    NumberField,
    Modal,
    TextArea,
    Select,
    CheckBoxGroup,
    RowControls,
    CardWidget,
    RadioGroup,
    TextBoxAddon,
    Button,
    Spinner,
    SmartAddress,
    Toggle,
    PhoneNumber,
    OrdersProductTable,
    ShippingMethodInput,
    BankSelectInput,
    DatePicker,
    TimePicker,
    DropZone,
  },
  data() {
    return {
      shops: null,
      createLoading: null,
      phoneCheckLoading: false,
      shopOptions: [],
      salesPersonOptions: [],
      boxSizeOptions: null,
      codToggle: localStorage.getItem("_codToggle") ? true : false,
      discountToggle: false,
      bankToggle: false,
      showCod: true,
      loading: false,
      hasSurcharge: false,
      isValidAddress: false,

      duplicateTimer: null,
      duplicateTimeLimit: 5,

      orderCreateForm: {
        user: null,
        salesPerson: null,
        orderCode: null,
        shippingMethod: [],
        weight: null,
        shippingCostCharged: 0,
        boxSize: null,
        codAmount: 0,
        discountAmount: 0,
        remarks: null,
        shopId: null,
        happyReturn: false,
        customer: {
          name: "กรุณาจัดส่งคุณ:",
          address: null,
          mobileNo: null,
          phoneNo: null,
          email: null,
          salesChannel: null,
          socialLink: null,
          showSocialLinkOnLabel: false,
        },
        products: {},
        bank: {
          name: null,
          amount: null,
          date: null,
          time: null,
          slip: null,
        },
      },
      isDraft: false,

      shippingMethods: [],
      phoneNoInsights: { returned: "", sameShop: "" },

      dupOrder: null,

      remoteAreas: null,

      // selectedShopError: null
    };
  },

  created() {
    this.loading = true;
    this.fetchOrderCode(() => {
      this.fetchShops(() => {
        this.fetchSalesAgents(() => {
          this.fetchShipping(() => {
            this.fetchRemoteAreas(() => {
              this.loading = false;
            })
          });
        });
      });
    });

    //pre load it
    this.fetchBoxSizes();

    // Pre-fill customer data
    if (
      this.$route.params.prefillData &&
      this.$route.params.prefillData.customer
    ) {
      let cust = this.$route.params.prefillData.customer;

      if (cust.selectedAddressIndex)
        cust.address = cust.address[cust.selectedAddressIndex];
      else if (cust.address && cust.address[0]) cust.address = cust.address[0];

      this.orderCreateForm.customer.name = cust.custName;
      this.orderCreateForm.customer.phoneNo = cust.phoneNo;
      this.orderCreateForm.customer.salesChannel = cust.scName;
      this.orderCreateForm.customer.address = {
        ...cust.address,
        district: "",
        subDistrict: "",
        province: "",
      };
    }
  },

  methods: {

    validateShopAddress() {
      // if (!this.orderCreateForm.shopId || !this.orderCreateForm.shippingMethod) return;
      // let shop = this.shops.find((shop) => shop.id === this.orderCreateForm.shopId);

      // // check if shop address is valid for eps
      // this.selectedShopError = null;
      // if (this.orderCreateForm.shippingMethod.includes("thaipost0")) {
      //   if (!shop || !shop.details || !shop.details.address || !shop.details.address.address || !shop.details.address.district || !shop.details.address.subDistrict || !shop.details.address.province || !shop.details.address.zipcode) {
      //     this.selectedShopError = {
      //       message: "Invalid shop address",
      //       shopId: shop.id
      //     }
      //   }
      // }
    },

    fetchRemoteAreas(callback) {
      axios.get("https://proship-dev.s3.ap-southeast-1.amazonaws.com/proship_files/remote_areas.json", {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }).then(({ data }) => {
        this.remoteAreas = data;
        if (callback) callback()
      }).catch(e => {
        console.log(e)
        if (callback) callback()
      })
    },

    async handlePhoneNoChange() {
      let phoneNo = this.orderCreateForm.customer.phoneNo;
      let shopId = this.orderCreateForm.shopId;
      this.phoneNoInsights = { returned: "", sameShop: "" };

      // Return package insight
      if (phoneNo) {
        let data = await this.$store.dispatch("util/orderReturnPkgHistory", { phoneNo });
        if (data && data.total)
          this.phoneNoInsights.returned = `ลูกค้ารายนี้มีประวัติการคืนพัสดุ ${data.returned} ครั้ง จากการจัดส่งผ่านระบบ ${data.total} ครั้ง`;
      }

      // same shop insight
      if (phoneNo && shopId) {
        let data = await this.$store.dispatch("util/orderHistoryWithShop", {
          phoneNo,
          shopId,
        });
        if (data && data.count)
          this.phoneNoInsights.sameShop = `ลูกค้ารายนี้ได้สั่งสินค้าจากร้านท่านแล้ว ${data.count} รายการ`;
      }
    },

    handleZipChange() {
      if (
        this.orderCreateForm.customer.address &&
        this.orderCreateForm.customer.address.zipcode &&
        this.orderCreateForm.shippingMethod &&
        this.orderCreateForm.shippingMethod.length
      )
        this.hasSurcharge = checkSurcharge(
          this.remoteAreas,
          this.orderCreateForm.shippingMethod[0],
          this.orderCreateForm.customer.address.zipcode
        );
    },

    handleBankChange() {
      if (this.bankToggle) this.codToggle = false;
      this.handleDiscountChange();
    },

    handleDiscountChange() {
      if (!this.discountToggle) this.orderCreateForm.discountAmount = 0;

      if (this.codToggle && this.orderCreateForm.totalSalesPrice)
        this.orderCreateForm.codAmount = Math.max(
          this.orderCreateForm.totalSalesPrice -
          this.orderCreateForm.discountAmount +
          this.orderCreateForm.shippingCostCharged,
          0
        );

      if (this.codToggle) {
        this.bankToggle = false;
        if (this.$refs.dropzone) this.$refs.dropzone.clear();
        this.orderCreateForm.bank.slip = null;
        localStorage.setItem("_codToggle", this.codToggle);
      } else localStorage.removeItem("_codToggle");
    },

    handleProductsChange(val) {
      Vue.set(
        this.orderCreateForm,
        "totalSalesPrice",
        val.selectedProductsPrice
      );
      Vue.set(this.orderCreateForm, "weight", val.selectedProductsWeight);
      Vue.set(this.orderCreateForm, "products", val.products);
    },

    autofillCustDetails(value) {
      if (!value) return;
      if (value.phone) this.orderCreateForm.customer.phoneNo = value.phone;
      if (value.name) this.orderCreateForm.customer.name = value.name;

      if (value.nameAddress) {
        let items = value.nameAddress.split("~");
        // preserve customer name on address change
        if (
          items.length &&
          (!this.orderCreateForm.customer.name ||
            this.orderCreateForm.customer.name == "กรุณาจัดส่งคุณ:")
        )
          this.orderCreateForm.customer.name = items[0];
      } else {
        value.raw = value.raw.replace(/\n/gi, "~");
        let items = value.raw.split("~");
        if (items.length) this.orderCreateForm.customer.name = items[0];
      }

      if (value.state) {
        // valid
        if (value.state == 2) this.isValidAddress = true;
        else if (value.state != 2 && this.isThaiPost)
          this.isValidAddress = true;
        else this.isValidAddress = false;
      }

      this.handleZipChange();
    },

    fetchOrderCode(callback) {
      this.$store.dispatch("orders/fetchOrderCode", {
        callback: (status, data) => {
          if (status) this.orderCreateForm.orderCode = data;
          this.orderCreateForm.user = this.authData.user.username || "N/A";
          if (callback) callback();
        },
      });
    },

    defaultWeight(shopId = this.orderCreateForm.shopId) {
      let shop = this.shops.find((shop) => shop.id === shopId);

      // check if shop address is valid for eps
      // this.validateShopAddress();

      if (shop) {
        if (shop.details.defaultWeight)
          this.orderCreateForm.weight = shop.details.defaultWeight;
        if (
          shop.details.shippingMethods &&
          shop.details.shippingMethods.length
        ) {
          let shippingMethods = shippingInfo("all");
          shippingMethods = shippingMethods.map((sm) => {
            sm.available = shop.details.shippingMethods.includes(sm.name);
            return sm;
          });
          this.shippingMethods = shippingMethods;

          if (
            localStorage.lastUsedSm &&
            shop.details.shippingMethods.includes(localStorage.lastUsedSm)
          )
            this.orderCreateForm.shippingMethod = [localStorage.lastUsedSm];
          else
            this.orderCreateForm.shippingMethod = [
              this.shippingMethods.filter((sm) => sm.available)[0].name,
            ];
        }

        if (!shop.details.walletId) {
          this.codToggle = false;
          this.showCod = false;
        } else this.showCod = true;

        localStorage.setItem("_lastUsedShop", shopId);
      }
    },

    checkDuplicate(e) {
      this.createLoading = true;
      let valueToSend = JSON.parse(JSON.stringify(this.orderCreateForm));

      this.$store.dispatch("orders/checkDuplicate", {
        data: {
          phoneNo: valueToSend.customer.phoneNo,
          zipcode: valueToSend.customer.address.zipcode + "",
        },
        callback: (status, data) => {
          if (status) {
            if (data.duplicate) {
              let audio = document.getElementById("error-audio");
              if (audio) audio.play();
              $("#duplicate-error-modal").modal("show");
              this.dupOrder = data.order;

              clearInterval(this.duplicateTimer);
              this.duplicateTimeLimit = 5;
              this.duplicateTimer = setInterval(() => {
                this.duplicateTimeLimit--;
                if (this.duplicateTimeLimit <= 0) clearInterval(this.duplicateTimer);
              }, 1000);
            }
            else this.handleFormSubmit();
          }
        },
      });

      if (e.submitter && e.submitter.value && e.submitter.value === "draft") this.isDraft = true;
    },

    handleFormSubmit() {
      $("#duplicate-error-modal").modal("hide");
      this.createLoading = true;
      let valueToSend = JSON.parse(JSON.stringify(this.orderCreateForm));
      valueToSend.shippingMethod =
        this.orderCreateForm.shippingMethod.toString();
      if (valueToSend.salesPerson)
        valueToSend.salesPerson = this.salesPersonOptions.find(
          (s) => s.value == valueToSend.salesPerson
        );

      if (!valueToSend.boxSize) delete valueToSend.boxSize;

      if (!this.codToggle) delete valueToSend.codAmount;

      if (!this.bankToggle) delete valueToSend.bank;

      //validate "thaipost", "shopee"
      let error = false;
      if (
        valueToSend.shippingMethod == "thaipost" ||
        valueToSend.shippingMethod == "shopee"
      ) {
        //weight cannot me more than 20,000gm
        if (valueToSend.weight > 20000)
          error = i18n.t("error.weight_greater_than_20K_error");
      }

      // validate: "thaipost" only
      if (valueToSend.shippingMethod.includes("thaipost")) {
        let shop = this.shops.find((s) => s.id === valueToSend.shopId);
        if (
          this.codToggle &&
          valueToSend.codAmount > 0 &&
          shop &&
          !shop.details.walletId
        )
          error = i18n.t("error.wallet_id_not_found_error");
      }

      //validate cod amount
      if (this.codToggle && !valueToSend.codAmount) error = "รบกวนใส่ยอด COD";

      if (error) {
        ToastFix(error, "danger");
        this.createLoading = false;
        return;
      }

      // auto detect showSocialLinkOnLabel
      if (
        valueToSend.customer.socialLink &&
        valueToSend.customer.socialLink.length
      )
        valueToSend.customer.showSocialLinkOnLabel = true;
      else valueToSend.customer.showSocialLinkOnLabel = false;

      // create 'order' or 'pre-order' based on query
      if (this.$route.query.type == "pre-shipment") {
        // this.$store.dispatch("preShipments/createPreShipment", {
        //   order: valueToSend,
        //   callback: (status, data) => {
        //     if (status) {
        //       this.$router.push({
        //         name: "Seller-PreShipment-List",
        //       });
        //     }
        //     this.createLoading = false;
        //   },
        // });
      } else {
        const action = this.isDraft ? "orders/createDraft" : "orders/createOrder"
        this.$store.dispatch(action, {
          order: valueToSend,
          callback: (status, data) => {
            if (status) {
              this.$router.push({
                name: "Seller-Order-Detail",
                params: { id: data.id },
              });

              if (data.balance) {
                const newAuthData = JSON.parse(JSON.stringify(this.authData));
                newAuthData.user.balance = data.balance;
                this.$store.commit("user/setAuthData", newAuthData);
              }
            }
            this.createLoading = false;
          },
        });
      }
    },

    handleShippingChange(shippingMethods) {
      // if (!shippingMethods) return;
      // let sm = shippingMethods[0];
      // if (sm === "ems" || sm === "thaipost" || sm === "thaipost0") {
      //   this.codToggle = false;
      //   this.showCod = false;
      //   this.orderCreateForm.codAmount = null;
      // } else {
      //   this.showCod = true;
      //   this.codToggle = true;
      //   this.orderCreateForm.codAmount = 0;
      // }
      if (shippingMethods && shippingMethods.length)
        localStorage.lastUsedSm = shippingMethods[0];

      this.handleZipChange();
      // this.validateShopAddress();
    },

    cancel() {
      this.$router.push({ name: "Seller-Order-List" });
    },

    cancelDuplicateErrorModal() {
      $("#duplicate-error-modal").modal("hide");
      this.createLoading = false;
    },

    checkPhoneNo(phone) {
      if (!phone) return;
      this.phoneCheckLoading = true;
      let url = `https://iwjkvg2m94.execute-api.ap-southeast-1.amazonaws.com/dev/get-address-by-phone/?phone=${phone}&secret=zaa123cvberty`;
      axios.get(url).then((response) => {
        if (response.data.not_found) alert("Not found");
        this.orderCreateForm.customer.address = {
          raw: response.data.original,
        };
        if (response.data.parsed && response.data.parsed.phone)
          this.orderCreateForm.customer.phoneNo = response.data.parsed.phone;
        this.phoneCheckLoading = false;
      });
    },

    fetchShops(callback) {
      this.shops = null;
      this.$store.dispatch("shops/fetchShops", {
        query: "responseSize=all",
        callback: (status, data) => {
          if (callback) callback();
          if (status) {
            this.shops = data;
            this.shops.forEach((shop) => { });
            if (this.shops) {
              this.shopOptions = this.shops.map((shop) => {
                return {
                  name: shop.details.name,
                  value: shop.id,
                };
              });

              let shopFromLs = localStorage.getItem("_lastUsedShop");
              if (shopFromLs) {
                this.orderCreateForm.shopId = shopFromLs;
              } else this.orderCreateForm.shopId = this.shopOptions[0].value;
            }
          }
        },
      });
    },

    fetchShipping(callback) {
      this.shippingMethods.push(shippingInfo("thaipost"));
      if (callback) callback();
    },

    fetchSalesAgents(callback) {
      // sales-agent => default to its name
      if (this.authData.user.role === "sales-agent") {
        this.salesPersonOptions = [
          { name: this.authData.user.username, value: this.authData.user.id },
        ];
        if (callback) callback();
      }

      // seller => fetch sales agents
      else {
        this.$store.dispatch("salesAgent/fetchSalesAgents", {
          callback: (status, data) => {
            if (status) {
              this.salesPersonOptions = data.map((d) => {
                return {
                  name: d.details.username,
                  value: d.id,
                };
              });
              this.salesPersonOptions.unshift({ name: "None", value: null });
            }
            if (callback) callback();
          },
        });
      }
    },

    fetchProducts(callback) {
      this.$store.dispatch("products/fetchProducts", {
        callback: (status, data) => {
          if (status) {
            this.product.rows = data;
            this.$refs.productsDatatable.refresh();
          }
          if (callback) callback();
        },
      });
    },

    fetchBoxSizes(callback) {
      if (this.generalSettings.thaiPostUserName) {
        this.$store.dispatch("orders/fetchBoxSizes", {
          username: this.generalSettings.thaiPostUserName,
          callback: (status, data) => {
            if (status) {
              this.boxSizeOptions = data;
            }
            if (callback) callback();
          },
        });
      }
    },
  },
  computed: {
    ...mapGetters("user", ["authData"]),
    ...mapGetters({ generalSettings: "settings/generalSettings" }),

    showProducts() {
      if (this.authData.user.role == "sales-agent") {
        return true;
      }
      return this.generalSettings && this.generalSettings.productMgt;
    },

    weightValidationRules() {
      return this.orderCreateForm.shippingMethod &&
        (this.orderCreateForm.shippingMethod[0] == "thaipost" ||
          this.orderCreateForm.shippingMethod[0] == "kerry" ||
          this.orderCreateForm.shippingMethod[0] == "jt" ||
          this.orderCreateForm.shippingMethod[0] == "flash" ||
          this.orderCreateForm.shippingMethod[0] == "thaipost0")
        ? "required"
        : null;
    },

    isThaiPost() {
      return (
        this.orderCreateForm.shippingMethod == "thaipost" ||
        this.orderCreateForm.shippingMethod == "shopee"
      );
    },

    dupTimerPercentage() {
      let percentage = Math.floor(100 - (this.duplicateTimeLimit * 100) / 5);
      return { x: percentage, y: percentage - 40 }
    }
  },
};
</script>

<style lang="scss" scoped>
#btn-duplicate-error-yes {
  background-image: url('../../../assets/wave.png');
  background-repeat: repeat-x;
  background-position: 0 -40%;
  transition: all 1.25s ease-out;
}

.variationsList {
  display: none;
  position: absolute;
  width: 150px;
  max-height: 150px;
  overflow: auto;
  top: 0;
  left: 0;
  transform: translate(-100%, -50%);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  background-color: whitesmoke;
  text-align: left;
  z-index: 100;

  .variationsListItem {
    background-color: white;
    padding: 0.2rem 1rem;
    margin: 0.2;

    &:hover {
      background-color: whitesmoke;
    }
  }
}

.childProductsList {
  max-width: 200px;

  .childProductsListItem {
    border: 1px solid map-get($variants, "success");
    padding: 0.1rem;
    margin: 0.1rem;
    border-radius: 0.2rem;
    font-size: 0.7rem;

    .btnCancel {
      cursor: pointer;
      margin-right: 0.2rem;
    }
  }
}

.btn-caret-up:hover .variationsList {
  display: block;
}

.fa-plus-circle,
.fa-expand {
  color: $brand-green-mid;
  font-size: 18px;
}

.fa-trash-alt {
  color: $brand-pink;
  font-size: 18px;
}

.shipping-warning:hover {
  text-decoration: underline;
  cursor: pointer;
}

.dup-header-text {
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;

  @include for-medium-devices-and-up {
    font-size: 2rem;
  }
}
</style>
